import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import {
  faArrowUpFromSquare,
  faDownload,
  faLink,
  faShareNodes,
  faSpinner,
  faTrash,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OutlineButton, PrimaryButton } from 'Components/Buttons';
import CloseButton from 'Components/modals/CloseButton';
import { Box, Drop, Heading, Layer, Text } from 'grommet';
import { isArray, isEmpty, isNil } from 'lodash-es';
import { memo } from 'preact/compat';
import { useEffect, useRef, useState } from 'preact/hooks';
import { useDispatch } from 'react-redux';
import { buildURL } from 'Shared/fetch';
import { trackMP } from 'Shared/mp';
import useFetch from 'use-http';

import { cn } from '@/lib/utils';
import { removeMediaFromMyAlbum } from '@/redux/slices/myAlbum/actions';

import { androidDevice, iOSDevice } from '../../../helpers';
import { removeMediaFromAlbum } from '../../../routes/habitat/components/Album/actions';
import { closeShareModal } from '../shareModalSlice/actions';
import style from './style.scss';

const DeleteButton = ({ mediaId: id, type, onDeleteSuccess, className }) => {
  const dispatch = useDispatch();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { put, loading, response, error } = useFetch(buildURL(), { credentials: 'include', cachePolicy: 'no-cache' });

  const onClose = () => {
    setShowConfirmation(false);
  };

  const deleteMedia = async () => {
    await put(type === 'photo' ? `/photos/${id}` : `/videos/${id}`, { disabled: true });

    if (response.ok) {
      dispatch(removeMediaFromAlbum({ id }));
      dispatch(removeMediaFromMyAlbum({ id }));
      dispatch(closeShareModal());
      onClose();
      onDeleteSuccess();
    }
  };

  return (
    <>
      <button
        type="button"
        aria-label="Delete Button"
        className={cn('preflight preflight-button flex items-center justify-center', className)}
        onClick={() => setShowConfirmation(true)}
      >
        <FontAwesomeIcon icon={faTrash} />
      </button>
      {showConfirmation && (
        <Layer position="center" onClickOutside={onClose} onEsc={onClose} background="white">
          <div className={style.deletePopupContainer}>
            <div>
              <CloseButton varient="grey" onClick={onClose} className={style.close} />
              <Heading level="4" color="var(--charcoal)" textAlign="center">
                Are you sure you want to delete
                <br />
                this moment?
              </Heading>
            </div>
            <div className={style.buttons}>
              <OutlineButton margin={{ right: '10px' }} label="Yes" loading={loading} onClick={deleteMedia} />
              <PrimaryButton label="No" margin={{ left: '10px' }} disabled={loading} onClick={onClose} />
            </div>
            {error && (
              <Text textAlign="center" size="xlarge" color="var(--red)">
                Something went wrong. Please try again.
              </Text>
            )}
          </div>
        </Layer>
      )}
    </>
  );
};
export const DeleteMediaButton = memo(DeleteButton);

export const generateFacebookURL = (html, hashtags) => {
  const shareURL = new URL('https://www.facebook.com/sharer/sharer.php');
  shareURL.searchParams.append('u', html);
  if (isArray(hashtags) && hashtags.length > 0) {
    shareURL.searchParams.append('hashtag', `#${hashtags[0]}`);
  }
  return shareURL.href;
};

export const FacebookShareLink = ({ htmlURL, hashtags, onClick, className }) => (
  <a
    className={cn('preflight preflight-button flex items-center justify-center', className)}
    href={generateFacebookURL(htmlURL, hashtags)}
    target="_blank"
    rel="noreferrer"
    onClick={onClick}
  >
    <FontAwesomeIcon icon={faFacebookF} />
  </a>
);

export const WebShare = ({ logShare, htmlURL, photoURL, videoURL, className }) => {
  const [showLoading, setShowLoading] = useState(false);

  const webShareHandler = async () => {
    try {
      setShowLoading(true);
      const fileUrl = (photoURL || videoURL).replace(/^.*\/\/[^\/]+/, '');
      const filename = fileUrl.split('/').pop();
      const response = await fetch(fileUrl);
      const blob = await response.blob();

      const file = new File([blob], filename, { type: blob.type });

      if (navigator.canShare && navigator.canShare({ files: [file] })) {
        await navigator.share({ files: [file] });
      } else {
        await navigator.share({ url: htmlURL });
      }
      logShare('webShare');
    } catch (error) {
      console.error('Webshare error', error);
    } finally {
      setShowLoading(false);
    }
  };
  return (
    <button
      type="button"
      onClick={webShareHandler}
      className={cn('preflight preflight-button flex items-center justify-center', className)}
    >
      {showLoading && <FontAwesomeIcon icon={faSpinner} spinPulse />}
      {!showLoading && androidDevice() && <FontAwesomeIcon icon={faShareNodes} />}
      {!showLoading && iOSDevice() && <FontAwesomeIcon icon={faArrowUpFromSquare} />}
    </button>
  );
};

export const generateTwitterURL = (html, hashtags, text, handle) => {
  const shareURL = new URL('https://twitter.com/intent/tweet');

  if (isArray(hashtags) && hashtags.length > 0) {
    shareURL.searchParams.append('hashtags', hashtags.join(','));
  }

  if (!isNil(text) && !isEmpty(text)) {
    shareURL.searchParams.append('text', text);
  }

  if (!isNil(handle) && !isEmpty(handle)) {
    shareURL.searchParams.append('via', handle);
  }

  shareURL.searchParams.append('tw_p', 'tweetbutton');
  shareURL.searchParams.append('url', html);
  return shareURL.href;
};

export const generateRedditURL = (html, title = '') => {
  const shareURL = new URL('https://www.reddit.com/submit');

  let modifiedTitle = title.trim();
  // Reddit has a limit for title length which is 300 character
  if (modifiedTitle.length > 300) {
    modifiedTitle = `${modifiedTitle.substring(0, 296)} ...`;
  }
  shareURL.searchParams.append('title', modifiedTitle);
  shareURL.searchParams.append('url', html);

  return shareURL.href;
};

export const CopyLink = ({ link, onClick, className }) => {
  const [linkCopied, setLinkCopied] = useState(false);
  const targetRef = useRef();

  useEffect(() => {
    let timeout;

    if (linkCopied) {
      timeout = setTimeout(() => {
        setLinkCopied(false);
      }, 2000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [linkCopied]);

  const copyToClipboardBtnHandler = () => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(link)
        .then(() => setLinkCopied(true))
        .catch((err) => console.error('Error while copying link via navigator.clipboard', err));
    } else {
      // if clipboard is not supported, attempt to use old alternative (deprecated)
      console.warn('clipboard is not supported, copy via execCommand.');
      const elem = document.createElement('input');
      elem.value = link;
      document.body.appendChild(elem);
      elem.select();

      try {
        document.execCommand('copy');
        setLinkCopied(true);
      } catch (err) {
        console.error('Error while copying link', err);
      } finally {
        document.body.removeChild(elem);
      }
    }

    onClick();
  };

  return (
    <>
      <button
        ref={targetRef}
        type="button"
        className={cn('preflight preflight-button flex items-center justify-center', className)}
        onClick={copyToClipboardBtnHandler}
      >
        <FontAwesomeIcon icon={faLink} />
      </button>

      {targetRef?.current && linkCopied && (
        <Drop align={{ bottom: 'top' }} target={targetRef.current} className={style.copyLinkPopupContainer}>
          <div className={style.wrapper}>Copied</div>
        </Drop>
      )}
    </>
  );
};

export const DownloadButton = ({ url, userLoggedIn, data, className }) => {
  const [showPopover, setShowPopover] = useState(false);
  const downloadButtonRef = useRef();

  const handleClick = (e) => {
    if (!userLoggedIn) {
      e.preventDefault();
      setShowPopover(true);
      setTimeout(() => setShowPopover(false), 3000);
      return;
    }

    trackMP('downloaded-ugc', {
      ugcData: data,
    });
  };

  return (
    <>
      <a
        ref={downloadButtonRef}
        href={url}
        download
        className={cn('preflight preflight-button flex items-center justify-center', className)}
        onClick={handleClick}
      >
        <FontAwesomeIcon icon={faDownload} />
      </a>

      {showPopover && downloadButtonRef.current && (
        <Layer
          position="top"
          modal={false}
          plain
          responsive={false}
          target={downloadButtonRef.current}
          onEsc={() => setShowPopover(false)}
          onClickOutside={() => setShowPopover(false)}
        >
          <Box pad="small" round="small" className={style.errorPopover}>
            <span>You need to be logged in to download this media</span>
            <div className={style.popoverArrow} />
          </Box>
        </Layer>
      )}
    </>
  );
};
