import { useInfiniteQuery } from '@tanstack/react-query';
import { FunctionalComponent } from 'preact';
import { useMemo } from 'preact/hooks';

import { getInfinitePlaylistsQueryOptions } from '@/queries/playlists';
import { useSelector } from '@/redux/helper';

import FollowButton from './FollowButton';
import NextEpisodeButton from './NextEpisodeButton';

interface HabitatVideoOverlayProps {
  mediaId: string;
  habitatId: string;
  videoType: string;
  isInsideTheModal: boolean;
}

const HabitatVideoOverlay: FunctionalComponent<HabitatVideoOverlayProps> = ({
  mediaId,
  habitatId,
  videoType,
  isInsideTheModal,
}) => {
  const habitat = useSelector((state) => state.allHabitats.find(({ _id }) => _id === habitatId));

  const { data: allPlaylistsData } = useInfiniteQuery({
    ...getInfinitePlaylistsQueryOptions({
      pageSize: 100,
    }),
    // since at the moment we use this query to check if the video is in the playlist, we don't need to refetch it on
    // refetchOnWindowFocus and refetchOnMount and also we consider the data as a stale data for 30 min
    staleTime: 30 * 60000,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const isInPlaylist = useMemo(() => {
    if (!allPlaylistsData?.list || !habitatId) return false;
    return allPlaylistsData.list.some((playlist) => playlist.videos?.includes(mediaId));
  }, [allPlaylistsData, mediaId, habitatId]);

  const nextVideoId = useMemo(() => {
    const playlist = allPlaylistsData?.list?.find((p) => p.videos?.includes(mediaId));
    if (!playlist?.videos) return null;
    const nextIndex = playlist.videos.indexOf(mediaId) + 1;
    return nextIndex < playlist.videos.length ? playlist.videos[nextIndex] : null;
  }, [allPlaylistsData, mediaId]);

  if (!habitat || (!isInPlaylist && videoType !== 'curated')) {
    return null;
  }

  return (
    <>
      <div className="pointer-events-auto absolute left-2 top-2 md:left-5 md:top-3" onClick={(e) => e.stopPropagation()}>
        <div className="flex flex-row items-center gap-2 md:flex-col">
          <div
            className={`h-[44px] w-[44px] overflow-hidden rounded-full ${
              isInsideTheModal ? 'md:h-[125px] md:w-[125px]' : 'md:h-[174px] md:w-[174px]'
            }`}
          >
            <img
              className="h-full !w-full rounded-full object-cover"
              src={habitat.profileImage}
              alt={`${habitat.title} profile`}
            />
          </div>

          <FollowButton habitatId={habitat._id} title={habitat.title} isInsideTheModal={isInsideTheModal} />
        </div>
      </div>

      {isInsideTheModal && nextVideoId && <NextEpisodeButton nextVideoId={nextVideoId} />}
    </>
  );
};

export default HabitatVideoOverlay;
