import { faHeart as faHeartOutline } from '@fortawesome/pro-regular-svg-icons';
import { faHeart } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'preact/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { API_BASE_URL } from 'Shared/fetch';
import useFetch from 'use-http';

import { cn } from '@/lib/utils';
import { getMediaQueryOptions } from '@/queries/media';
import { updateAlbumData } from '@/redux/slices/myAlbum/actions';

import { updateHabitatAlbumData } from '../../routes/habitat/components/Album/actions';

const LikeButton = ({ data, onClick = () => {}, mediaId, type, noCount, className }) => {
  const queryClient = useQueryClient();
  const logged = useSelector((state) => state.user.logged);
  const dispatch = useDispatch();
  const [isLiked, setIsLiked] = useState(data.isLiked);
  const [likes, setLikes] = useState(data.likes);
  const { put } = useFetch(API_BASE_URL, { credentials: 'include', cachePolicy: 'no-cache' });

  useEffect(() => {
    setIsLiked(data.isLiked);
  }, [data.isLiked, mediaId]);

  useEffect(() => {
    setLikes(data.likes);
  }, [data.likes, mediaId]);

  const likeContent = async () => {
    try {
      onClick();
      if (mediaId) {
        await put(`${type === 'photo' ? 'photos' : 'videos'}/${mediaId}/like`);
        queryClient.invalidateQueries({ queryKey: getMediaQueryOptions(mediaId).queryKey });
      }

      const newIsLiked = !isLiked;
      const newLikes = newIsLiked ? likes + 1 : likes - 1;

      setIsLiked(newIsLiked);
      setLikes(newLikes);

      // we need to update the state so when users navigate back and forth they see same value
      if (mediaId) {
        const payload = { id: mediaId, data: { isLiked, usersLike: likes } };
        dispatch(updateHabitatAlbumData(payload));
        dispatch(updateAlbumData(payload));
      }
    } catch (err) {
      console.error('Error trying to like content:', err);
    }
  };

  return (
    <button
      type="button"
      onClick={logged ? likeContent : undefined}
      className={cn(
        'preflight preflight-button relative flex items-center justify-center',
        !logged && 'cursor-default',
        className,
      )}
    >
      <FontAwesomeIcon icon={isLiked ? faHeart : faHeartOutline} />
      {!noCount && (
        <span className="pointer-events-none absolute inset-0 flex items-center justify-center text-white">
          {likes < 1000 ? likes : `${(likes / 1000).toFixed(1)}k`}
        </span>
      )}
    </button>
  );
};

export default LikeButton;
