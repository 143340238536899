import { infiniteQueryOptions, queryOptions } from '@tanstack/react-query';

import { buildURL, get, post, put } from '@/shared/fetch';

import { Pagination, PaginationQueryParams } from '../types';
import { getInfiniteQueryFn, getQueryFn } from '../utils';
import { AdminUploadMediaRequest, AdminUploadMediaResponse, GetMediaResponse, Video } from './types';

interface InfiniteVideosQuerySearchParams extends PaginationQueryParams {
  type?: string;
  habitatIds?: string;
  search?: string;
  highlight: string;
}

export const getInfiniteVideosQueryOptions = (searchParams?: InfiniteVideosQuerySearchParams) => {
  const { pageSize = 12 } = searchParams ?? {};
  return infiniteQueryOptions({
    queryKey: ['videos', 'list', searchParams],
    initialPageParam: 1,
    queryFn: getInfiniteQueryFn<Pagination<Video>>('/videos', searchParams),
    getNextPageParam: (lastPage, _, lastPageParams) =>
      !lastPage ? 1 : lastPage.total > pageSize * lastPageParams ? lastPageParams + 1 : undefined,
    select: ({ pages }) =>
      pages.length > 0
        ? ({ total: pages[pages.length - 1].total, list: pages.flatMap((page) => page.list) } as Pagination<Video>)
        : undefined,
  });
};

export const getMediaQueryOptions = (mediaId: string | undefined | null) =>
  queryOptions({
    enabled: Boolean(mediaId),
    queryKey: ['media', 'detail', mediaId],
    queryFn: getQueryFn<GetMediaResponse>(`/media/${mediaId}`),
    select: ({ media }) => media,
  });

export const getMedia = (mediaId: string) => get<GetMediaResponse>(buildURL(`/media/${mediaId}`)).then(({ media }) => media);

export const adminUploadMediaMutationFn = (args: AdminUploadMediaRequest) =>
  post<AdminUploadMediaResponse>(buildURL('/admin/media'), args);

export const updateVideoMutationFn = async (
  args: Omit<Partial<Video>, 'id'> & { overlayThumbnail?: boolean; playlists?: string[] },
) => put<void>(buildURL(`/videos/${args._id}`), args);
